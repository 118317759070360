export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (
    location.pathname.includes('pricing') ||
    location.pathname.includes('legal')
  ) {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 1);

    return false;
  }

  return true;
};
