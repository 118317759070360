exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-dpa-js": () => import("./../../../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-hr-js": () => import("./../../../src/pages/solutions-hr.js" /* webpackChunkName: "component---src-pages-solutions-hr-js" */),
  "component---src-pages-solutions-recruitment-js": () => import("./../../../src/pages/solutions-recruitment.js" /* webpackChunkName: "component---src-pages-solutions-recruitment-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

